.bg_image_container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: -4;

  max-width: 540px;
  max-height: 718px;
  width: 100%;
  height: 100%;
  opacity: .15;
}