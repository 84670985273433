@import 'variables';

body {
  ul {
    list-style: disc;
    padding-left: 18px;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
  }
}

@import 'components';

body {
  font-size: 16px;
  line-height: 25px;
  color: #333;
  background-color: #fff;

  max-width: 100vw;
  overflow-x: hidden;

  &.menu-open {
    position: fixed;

  }

  > main {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
  }

}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5 {
  font-family: $secondary-font;
  @apply font-bold;
  font-size: clamp(24px, 50px, 6vw);
  line-height: 1.2;
  margin-bottom: 30px;
}

h1 {
  font-size: clamp(45px, 80px, 8vw);
  line-height: 1.2;
}

.the_content {
}

.stretched-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.max-content-width {
  max-width: 965px;
  margin: auto;
}

.two_columns {
  @apply grid lg:grid-cols-2 gap-4;
}