@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;700;900&display=swap');

$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Lato', sans-serif;

body {
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6, .lato {
  font-family: $secondary-font;
}