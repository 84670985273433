.btn {
  display: inline-block;
  text-align: center;
  border: 3px solid;
  transition: all .2s;
  border-radius: 6px;
  font-weight: 700;
  text-decoration: none !important;

  padding: 5px 25px;

  &-purple {
    @apply bg-zz-purple text-white border-zz-purple;

    &:hover {
      @apply bg-white text-zz-purple;
    }
  }

  &-purple-outline {
    @apply bg-white text-zz-purple border-zz-purple;

    &:hover {
      @apply bg-zz-purple text-white;
    }
  }

  &-green-outline {
    @apply bg-zz-green text-white border-white;

    &:hover {
      @apply bg-white text-zz-green;
    }
  }
}