.alternating_textblocks {
  .alternating_textblock {

    &:not(:last-of-type) {
      margin-bottom: -60px;
    }

    .inner {
      padding: 100px 0;
      padding-right: 50px;
      max-width: 1100px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        right: 100%;
        top: 0;
        width: 100%;
        height: 100%;
        @apply bg-zz-green-lighter;
      }

      .the_content {
        max-width: 900px;
      }
    }


    &.has_image {
      .inner {
        max-width: unset;
        display: flex;
        justify-content: space-between;
        padding-right: 0;
      }

      .image-container {
        margin-top: -120px;
        margin-bottom: -100px;
        z-index: 111;
        position: relative;
        max-width: 480px;
        border-radius: 15px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &:nth-child(even) {
      .inner {
        @apply bg-zz-green;
        margin-left: auto;
        padding-right: unset;
        padding-left: 50px;
        @apply text-white;

        &:before {
          right: unset;
          left: 100%;
          @apply bg-zz-green;
        }
      }

      &.has_image {
        .inner {
          padding-left: 0;
        }
        .image-container {
          order: -2;
          margin-right: 30px;
        }
      }
    }

    &:nth-child(odd) {
      .inner {
        @apply bg-zz-green-lighter;
      }
    }
  }
}