.archive-documentatie {
  .filter-section {
    margin-top: 30px;
    margin-bottom: 50px;

    @apply flex justify-center gap-24;

    button {
      border: none;
      padding: 5px 20px;
      border-left: 12px solid;
      border-right: 12px solid;
      @apply border-l-zz-purple border-r-zz-purple-lighter;
      @apply bg-zz-purple-lighter text-black  transition-all;

      &.active, &:hover {
        @apply bg-zz-purple text-white border-r-zz-purple;
      }
    }
  }

 .documentatie-items {
   @apply grid lg:grid-cols-3 gap-24;

   [data-filter-cat] {
     display: none;

     &.active {
       display: block;
     }
   }
 }
}