@import '../variables';

.template-home {
  .home-header {
    position: relative;

    h1, h2, h3, h4, h5 {
      font-size: 40px;
      line-height: 48px;
    }

    .text-container {
      @apply lg:grid-cols-2 grid py-24 lg:py-44;

      .buttons {
        .btn {
          margin-right: 10px;
        }
      }
    }

    .bg-layer {
      @apply relative lg:absolute overflow-auto lg:overflow-hidden;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      @apply grid lg:grid-cols-2;

      .image-container {
        @apply mb-24 lg:mb-0;

        img {
          @apply m-auto lg:ml-auto;
          @apply lg:translate-x-[10%] lg:translate-y-[-10%];
        }
      }

      img {
        max-width: 600px;
        margin-right: -20%;
        border-radius: 50%;

        width: 100%;
        aspect-ratio: 1/1;

        object-fit: cover;
      }
    }
  }

  .diensten-cards {
    @apply grid grid-cols-1 lg:grid-cols-3 gap-12;

    @apply -mb-44;

    ul {
      list-style: none;
      padding: 0;
    }

    z-index: 1;
    position: relative;
  }

  .text-group {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-12 bg-zz-green-lighter;

    @apply pt-72 pb-40;

    position: relative;

    .the_content {
      z-index: 1;
      position: relative;
      @apply px-4;
      @include container-spacing();
    }

    .image-container {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        max-height: 500px;
        object-fit: cover;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 5vw;
      background-color: #fff;

      @apply hidden lg:block;
    }
  }

  .meld-je-aan-items {
    .items {
      @apply grid lg:grid-cols-2 gap-24;

      img {
        max-width: 400px;
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 50%;
        margin: 0 auto 30px auto;
      }

      .content {
        text-align: center;

        .title {
          font-size: 40px;
          line-height: 46px;
        }

        .text {
          font-size: 15px;
          line-height: 21px;
          max-width: 330px;
          width: 100%;
          margin: 0 auto 30px auto;
        }
      }
    }
  }

  .news-items {
    @apply grid grid-cols-1 lg:grid-cols-3 gap-12;

    margin-bottom: 50px;
  }

  .faq-section {
    position: relative;

    .content {
      @apply grid grid-cols-1 lg:grid-cols-2 gap-12;

      .faq-items {
        @apply bg-zz-green text-white;
        border-radius: 21px;
        padding: 0 20px;

        .faq-item {
          padding: 40px 0;

          &:not(:last-of-type) {
            border-bottom: 1px solid #fff;
          }

          .faq-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            i {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              @apply bg-white text-black;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .title {
              font-weight: bold;
              font-size: 20px;
              line-height: 28px;
              margin: 0;
            }
          }

          .faq-content {
            margin-top: 10px;

            font-size: 16px;
            line-height: 23px;
          }

        }
      }
    }

    .bg-layer {
      z-index: -4;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 180px;
      height: calc(100% - 180px);
      @apply grid grid-cols-3;
      @include container-spacing();


      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @apply col-span-2;
      }
    }
  }
}