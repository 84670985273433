footer {
  ul {
    list-style: none;
    padding-left: 0;
  }

  .top-footer{
    @apply bg-zz-green-lighter;

    .content {
      @apply gap-24 py-24;

      font-size: 16px;
      line-height: 23px;
      display: flex;
      justify-content: center;

      @apply flex-col lg:flex-row;


      .logo {
        max-width: 160px;
      }

      .header {
        @apply font-bold;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 5px;
      }

      .main-footer-menu {
        ul {
          margin: 0;
          padding:0;

          li {
            margin-bottom: 8px;
          }
        }
      }

      .contact-info {
        a {
          @apply block;
        }
      }
    }
  }

  .bottom-bar {
    @apply bg-zz-green-dark text-white font-thin py-4;

    .content {
      @apply block lg:flex;

      .menu-bottom-bar-container {
        flex: 1;
      }

      ul {
        @apply block lg:flex;
        justify-content: space-between;

        a {
          @apply text-white hover:font-normal transition-all;
        }
      }

      .created-by {
        @apply ml-0 lg:ml-[30px] mt-4 lg:mt-0;
        display: flex;
        align-items: center;
        img {
          margin-left: 10px;
          @apply transition-all;
          &:hover {
            cursor: pointer;
            opacity: .5;
          }
        }
      }
    }
  }
}