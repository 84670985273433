
.blob {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    border-radius: 38% 0% 74% 70% / 76% 10% 32% 85%;
}