@import "../variables";

.archive-team {
  .team-categories {
    .team-category {
      &:not(:first-of-type) {
        padding-top: 60px;
      }

      &:not(:last-of-type) {
        padding-bottom: 60px;
        border-bottom: 2px solid rgba(#000, .08);
      }

      .category-title {
        font-family: $primary-font;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        text-align: center;
      }

      .team-items {
        @apply grid grid-cols-1 lg:grid-cols-3 gap-12;
      }
    }
  }

  position: relative;
  .bg-image {
    z-index: -4;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-10%);
    width: 100%;
    max-height: 700px;
  }
}