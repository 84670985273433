.archive_team_item {
  .inner {
    .thumbnail {
      margin: 0 auto 20px auto;
      width: 100%;
      max-width: 260px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }

    .name {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      margin: 0;
    }

    .function {
        text-align: center;
      font-size: 20px;
      line-height: 33px;
      display: block;
    }
  }
}