.template-default {
  .stack-header {
    .inner {
      @apply grid lg:grid-cols-2 gap-24;

      .image-container {
        max-height: 417px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}