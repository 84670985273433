.archive_documentatie_item {
  .inner {
    max-width: 360px;
    margin: auto;
    background: white;
    border-radius: 15px;
    position: relative;
    @apply shadow-2xl;

    .image-container {
      height: 228px;
      img {
        border-radius: 15px 15px 0 0;
        height: 228px;
        width: 100%;
        object-fit: cover;
      }
    }

    .content {
      padding: 20px 30px;

      .title {
        @apply font-medium text-center block;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 20px;
      }
    }

    .stretched-link {
      position: absolute;
      top: 0;
      left:0;
      width: 100%;
      cursor: pointer;
      height: 100%;
    }
  }
}