.content-blocks {
  max-width: 100vw;
  overflow-x: hidden;

  .bg-image-container {
    .on-top {
      position: relative;
      z-index: 1;
    }

    position: relative;

    .bg-image {
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-height: 700px;
      height: 100%;
      z-index: 0;

      padding: 30px 0;

      &.green-logo {
        img {
          opacity: 15%;
        }
      }

      img {
        width: 100%;
        max-height: 100%;

      }
    }
  }

  .block-type-text_with_image {
    .inner {
      @apply grid grid-cols-1 lg:grid-cols-3 gap-24;

      .big {
        @apply lg:col-span-2;
      }
    }

    .top {
      display: flex;
      align-items: center;
    }

    .bottom {
      .image-container {
        order: 3;
        display: flex;
        justify-content: flex-end;

        @apply mr-auto lg:mr-[-10%] ml-auto mt-8 lg:mt-0;

        img {
          min-height: 600px;
          max-width: 600px;
          aspect-ratio: 1/1;
          border-radius: 100%;
          object-fit: cover;
        }
      }
    }

    &.image-on-the-left {
      .top {
        .inner {
          > div {
            &:nth-child(1) {
              order: 3;
            }
          }
        }
      }

      .bottom {
        .image-container {
          @apply lg:mr-auto lg:ml-[-30%];
        }

        .inner {
          > div {
            &:nth-child(2) {
              order: 1;
            }
          }
        }
      }
    }
  }

  .block-type-arrow {
    $arrow-width: 180px;
    $arrow-height: 80px;

    .arrow-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .arrow-down {
        width: 0;
        height: 0;
        border-left: $arrow-width solid transparent;
        border-right: $arrow-width solid transparent;
        border-top: $arrow-height solid white;
      }
    }


    &.white_to_green {
      @apply bg-zz-green-lighter;

      .arrow-down {
        @apply border-t-zz-white;
      }
    }

    &.green_to_white {
      @apply bg-zz-white;

      .arrow-down {
        @apply border-t-zz-green-lighter;
      }
    }
  }

  .block-type-faq {
    h2 {
      @apply font-semibold uppercase;
    }
  }

  .block-type-color_textboxes {
    .inner {
      .textbox-row {
        position: relative;
        padding: 45px;

        &:before {
          top: 0;
          right: 0;
          width: 100vw;
          height: calc(100% + 50px);
          content: '';
          position: absolute;
        }

        .content {
          position: relative;
          z-index: 1;
        }

        &.color {
          &-light_green {
            &:before {
              @apply bg-zz-green-lighter;
            }
          }

          &-green {
            @apply text-white;
            &:before {
              @apply bg-zz-green;
            }
          }
        }

        &:nth-of-type(even) {
          @apply lg:ml-[10vw];

          &:before {
            left: 0;
            right: auto;
          }
        }

        &:nth-of-type(odd) {
          @apply lg:mr-[10vw];
        }

        &:last-of-type {
          &:before {
            height: 100%;
          }
        }

        &.has-image {
          padding: 0;

          .colored-textbox-content {
            padding: 45px;
          }

          .content {
            display: flex;
            justify-content: space-between;

            @apply flex-col lg:flex-row;

            .text-box-image {
              max-width: 474px;

              img {
                height: calc(100% + 60px);
                width: 100%;
                object-fit: cover;
                border-radius: 15px;
                margin-top: -30px;

                @apply mb-8 lg:mb-0;
              }
            }
          }
        }
      }
    }
  }

  .block-type-video {
    iframe {
      max-width: 760px;
      height: auto;
      width: 100%;
      aspect-ratio: 16/9;
      margin: auto;
    }
  }

  .block-type-news {
    .posts {
      @apply grid lg:grid-cols-3 gap-24;
    }
  }

  .block-type-linkjes_in_cirkels {
    .links-container {
      @apply grid grid-cols-1 lg:grid-cols-3 gap-24;

      .link {
        .inner {
          position: relative;
          max-width: 280px;
          margin: auto;
          width: 100%;
          aspect-ratio: 1/1;

          &:before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            transition: all .2s;
            @apply bg-zz-purple-secondary;
            border-radius: 50%;
          }

          .bg-img {
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .content {
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 50%;
            position: relative;
            color: white;
            background: rgba(black ,.4);
            display: flex;
            justify-content: center;
            align-items: center;

            .title {
              font-size: 25px;
              line-height: 1.2;
              max-width: 180px;
              text-align: center;
            }
          }
        }



        &:hover {
          .inner {
            .content .title {
              @apply font-bold;
            }

            &:before {
              top: -10px;
              left: -10px;
              width: calc(100% + 20px);
              height: calc(100% + 20px);
            }
          }
        }
      }
    }
  }

  .block-type-activiteitenaanbod {
    .title {
      h2 {
        text-align: center;
      }
    }

    table {
      width: 100%;
      max-width: 1160px;
      margin: auto;

      th {
        @apply bg-zz-green text-white font-semibold;
        font-size: 24px;
        line-height: 1.2;
        padding: 10px;
      }

      .item {
        td {
          @apply bg-zz-green-lighter text-black;
          border-bottom: 1px solid white;
          text-align: center;
          padding: 10px;

          &:nth-of-type(2) {
            @apply bg-white;
            border-bottom: 1px solid #F6F6F6;
          }

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}