.archive_leerbedrijf_item {
  .inner {
    position: relative;
    max-width: 360px;
    margin: auto;
    background: white;
    border-radius: 15px;
    @apply shadow-2xl;
    height: 100%;
    display: flex;
    flex-direction: column;

    .image-container {
      height: 228px;
      padding: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 20px 30px;

        .title {
            @apply font-medium text-center block;
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 20px;
        }

        .link-container {
          flex: 1;
          display: flex;
          align-items: flex-end;

          .link {
            display: block;
            width: auto;
            flex: 1;
          }
        }
    }
  }
}