.form-container {
  input:not([type="submit"]):not([type="file"]), select, textarea {
    outline: none!important;
    font-size: 14px;
    line-height: 1.2;
    border: 1px solid;
    border-radius: 10px;
    @apply border-zz-grey-dark ;
    font-weight: normal !important;
  }

  .gfield_label {
    font-size: 14px;
    font-weight: normal !important;
    line-height: 1.2;
    @apply text-zz-grey-darker;
  }

  .gfield-has-floating-label {
    position: relative;

    label {
      margin: 0;
      position: absolute;
      top: 9px;
      left: 10px;
      @apply transition-all;
    }

    &.gfield--active {
      label {
        top: 0;
        transform: scale(0.75);
        opacity: .7;
        transform-origin: top left;
      }
    }
  }

  input[type="submit"] {
    display: inline-block;
    text-align: center;
    border: 3px solid;
    transition: all .2s;
    border-radius: 6px;
    font-weight: 700;
    text-decoration: none !important;

    padding: 5px 25px;

    @apply bg-zz-purple text-white border-zz-purple;

    &:hover {
      @apply bg-white text-zz-purple;
    }
  }
}