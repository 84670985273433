$arrow-width: 180px;
$arrow-height: 80px;

.arrow_header {
  margin-bottom: -#{$arrow-height};

  .content {
    background: white;
    padding-bottom: 45px;
  }

  .arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .arrow-down {
    width: 0;
    height: 0;
    border-left: $arrow-width solid transparent;
    border-right: $arrow-width solid transparent;
    border-top: $arrow-height solid white;
  }
}

@media (max-width: 500px) {
  .arrow_header {
    margin-bottom: -40px;

    .arrow-down {
      border-left: 130px solid transparent;
      border-right: 130px solid transparent;
      border-top: 40px solid white;
    }
  }
}