.team_slider {
  .team-slider {
    padding: 0 30px;
    position: relative;

    .splide__slide {

      .archive_team_item {
        @apply transition-all;
        opacity: 50%;
        transform: scale(.7);
      }

      &.is-next, &.is-prev {
        .archive_team_item {
          opacity: 75%;
          transform: scale(.8);
        }
      }

      &.is-active {
        .archive_team_item {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    .splide__arrows {
      position: absolute;

      left:0;
      right:0;

      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;

      button > i {
        font-size: 6rem;
        @apply text-zz-purple;
      }
    }
  }
}