.card {
  @apply shadow-md;
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  .image-container {
    width: 100%;
    height: 225px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-align: center;

      > * {
        font-size: 30px;
        @apply font-bold;
        line-height: 36px;
        margin: 0;
      }
    }
  }

  .content {
    flex: 1;
    padding: 20px;
    border-radius: 0 0 15px 15px;
    @apply bg-white;

    display: flex;
    align-items: center;

    ul {
      li {
        font-size: 18px;
        line-height: 40px;

        i {
          line-height: 40px;
          @apply text-zz-green;
        }

        a {
          @apply text-black transition-all;
          display: flex;

          i {
            margin-right: 10px;
            @apply transition-all;
          }

          &:hover {
            @apply text-black font-bold;

            i {
              margin-right: 5px;
              margin-left: 5px;
              text-decoration: none !important;
            }
          }
        }
      }
    }
  }
}