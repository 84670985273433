@mixin container-spacing($location: 'left') {
  // set property to default value
  $type: padding-left;
  $value: 0;
  @if ($location == "left") {
    $type: padding-left;
  }
  @if ($location == "right") {
    $type: padding-right;
  }

  // bootstrap breakpoints
  $sizes: 576px, 768px, 992px, 1200px, 1400px;

  // create media queries for each breakpoint
  @each $size in $sizes {
    @media (min-width: $size) {
      $value: calc((100vw - #{$size}) / 2);
      #{$type}: $value;
    }
  }
}