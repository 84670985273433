.single-nieuwsberichten {
  .news-header  {
    overflow: hidden;

    .inner {
      @apply grid lg:grid-cols-2 gap-24;
    }

    .top {
      .inner {
        padding-top: 40px;

        .date {
          font-family: $secondary-font;
          font-size: 20px;
          line-height: 1.2;
          @apply font-bold;
          color: black;
          opacity: .66;
          margin-bottom: 20px;
        }

        .title {
          @apply font-bold text-zz-purple;
          font-size: 40px;
          line-height: 1.2;
          margin-bottom: 30px;
        }

        .auteur {
          display: flex;
          align-items: center;
          img {
            width: 55px;
            height: 55px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 17px;
          }
          .name {
            font-size: 20px;
            line-height: 1.2;
          }
        }
      }
    }

    .bottom {
      .image-container {
        img {
          border-radius: 50%;
          aspect-ratio: 1/1;
          margin-left: auto;
          max-height: 748px;
          object-fit: cover;

          @apply px-8 lg:px-0 mt-0 lg:mt-[-20%] lg:translate-x-[20%];
        }
      }
    }
  }

  .related-news {
    .posts {
      @apply grid lg:grid-cols-3 gap-24;
    }
  }
}