@import "../../variables";

header.top-header {
  z-index: 11111;
  @apply bg-zz-green-light;

  i {
    @apply text-zz-purple;
  }

  .content {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      a {
        margin-right: 40px;

      }
    }

    .right {
      a {
        margin-left: 30px;
      }
    }
  }
}

header.main-header {
  z-index: 999999999999;
  @apply shadow-md bg-white;

  position: sticky;
  top: 0;

  .content {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .logo {
      padding: 5px 0;
      margin-right: 35px;

      img {
        max-width: 119px;
        margin-bottom: -30px;
      }
    }

    .mobile-menu-content {
      align-items: center;
      @apply flex lg:hidden;

      .mobile-switch {
        font-size: 38px;

        .on-active {
          display: none;
        }

        &.active {
          .on-active {
            display: inline-block;
          }

          .on-inactive {
            display: none;
          }
        }
      }
    }

    .menu-content {
      @apply hidden lg:block;

      .menu-hoofdmenu-container {
        height: 100%;
      }


      ul.menu {
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;

        li {
          padding: 0 20px;
          display: flex;
          align-items: center;
          @apply transition-all;

          a {
            text-decoration: none !important;
            @apply transition-all;
            @apply text-zz-green-dark hover:text-black font-bold;
          }

          i {
            @apply text-zz-purple transition-all;
          }

          &:hover {
            @apply bg-zz-purple text-white;

            a, i {
              @apply text-white;
            }

          }
        }

        .menu-item-has-children {
          position: relative;

          .sub-menu {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: max-content;
            max-width: 260px;

            padding: 20px 0 20px 20px;

            margin: 0;

            li {
              margin: 0;
              padding: 20px;
              @apply transition-all;

              border-left: 3px solid transparent;

              &:hover {
                @apply bg-zz-purple-dark border-zz-grey;
              }
            }

            @apply bg-zz-purple text-white;
          }

          &:hover {
            .sub-menu {
              display: block;
            }
          }
        }
      }

      i {
        @apply text-zz-purple;
      }
    }
  }
}