
.faq-section {
  .faq-item {
    .faq-header {
      cursor:pointer;

      i {
        @apply transition-all;
      }
    }

    .faq-content {
      display: none;
    }

    &[aria-expanded="true"] {
      .faq-content {
        display: block !important;
      }
    }

    &[aria-expanded="true"] {
      .faq-header i {
        transform: rotate(180deg);
      }
    }
  }
}


.faq-items {
  margin-bottom: 75px;

  .faq-categories {
    margin-bottom: 75px;
    @apply flex justify-center gap-4 lg:gap-24 flex-col lg:flex-row;

    button {
      border: none;
      padding: 5px 20px;
      border-left: 12px solid;
      border-right: 12px solid;
      @apply border-l-zz-purple border-r-zz-purple-lighter;
      @apply bg-zz-purple-lighter text-black  transition-all;

      &[data-active="true"], &:hover {
        @apply bg-zz-purple text-white border-r-zz-purple;
      }
    }
  }

  .faq-sections {
    .faq-section {
      @apply shadow-2xl;
      max-width: 900px;
      margin: auto;
      display: none;

      &[data-active="true"] {
        display: block;
      }

      .faq-item {
        border-left: 5px solid #fff;

        .faq-header {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          h2, .title {
            font-size: 20px;
            line-height: 28px;
            margin: 0;
            @apply text-zz-green-2;
          }

          .toggle-content {
            i {
              width: 44px;
              height: 44px;
              @apply bg-zz-green-lighter text-black transition-all;
              border-radius: 50%;
              display: flex;
              font-size: 22px;
              line-height: 1.2;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .faq-content {
          padding: 0 20px 20px 20px;
          display: none;
        }

        &[data-active="true"] {
          .faq-header {
            .toggle-content {
              i {
                @apply bg-zz-green-2 text-white;
              }
            }
          }

          @apply border-zz-green-dark;
          @apply bg-zz-green-lighter;

          .faq-content {
            display: block;
          }
        }
      }
    }
  }
}