.mobile_menu {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top:0;
  padding-top: 100px;
  background-color: #fff;
  display: none;
  z-index: 1111111;

  ul {
    list-style: none;
    padding:0;

    .sub-menu {
      padding-left: 10px;
    }
  }

  &.active {
    display: block;
  }

  .content {
    padding-top: 30px;

    .menu {
      > .menu-item {
        margin-bottom: 12px;

        > a {
          @apply font-semibold;
          font-size: 24px;
          line-height: 1.2;

          &:hover, &:active {
            @apply text-zz-purple;
          }

          display: flex;
          justify-content: space-between;
          gap: 20px;
        }

        &.current_page_item {
          > a {
            @apply text-zz-purple;
          }
        }
      }
    }

    .sub-menu {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}