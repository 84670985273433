.stack-container {
  display: grid;
  grid-template: 1fr / 1fr;

  &.stop-on-lg {
    > * {
      @apply lg:col-span-stack lg:row-span-stack;
    }
  }

  &:not(.stop-on-lg) {
    > * {
      @apply col-span-stack row-span-stack;
    }
  }

  .top {
    z-index: 2;
  }

  .bottom {
    z-index: 1;
  }
}