$spacing: 100px;
.spacing {
  padding-top: $spacing;
  padding-bottom: $spacing;

  &-top {
    padding-top: $spacing;
  }

  &-bottom {
    padding-bottom: $spacing;
  }
}

@media (max-width: 991px) {
    .spacing {
        padding-top: $spacing / 2;
        padding-bottom: $spacing / 2;

        &-top {
        padding-top: $spacing / 2;
        }

        &-bottom {
        padding-bottom: $spacing / 2;
        }
    }
}