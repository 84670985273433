.template-contact {
  position: relative;

  .content {
    margin-bottom: 100px;

    .inner {
      @apply grid lg:grid-cols-2 gap-24;

      border-radius: 20px;
      position: relative;
      @apply p-8 lg:p-[40px];
      @apply bg-zz-green-lighter;



      .right {
        margin-bottom: -80px;
        position: relative;
        @apply p-8 lg:p-[40px];
        @apply bg-zz-green text-white;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100%;
          @apply bg-zz-green;
          content: '';
          z-index: 1;
        }

        .the_content {
          z-index: 2;
          position: relative;
        }

        .contact-links  {
          margin: 25px 0;
          a {
            display: block;

            @apply text-white hover:text-white;
          }
        }
      }

      .left {
      }
    }
  }

  .bg_image {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 5%;
    width: 100%;
    max-width: 740px;
    transform: translate(-10%, 50%);
  }
}