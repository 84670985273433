@import '../../variables';

.archive_news_item {
  display: flex;
  justify-content: center;

  .inner {
    max-width: 360px;
    width: 100%;

    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 360px;
      object-fit: cover;
      border-radius: 25px;
      margin-bottom: -80px;
    }

    .content {
      flex:1;
      display: flex;
      flex-direction: column;
      padding: 20px;
      z-index: 1;
      position: relative;
      margin-right: 20px;
      background: white;

      .title {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      .date {
        font-family: $secondary-font;
        font-size: 15px;
        line-height: 18px;
        opacity: .5;
        @apply font-bold;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 10px;
      }

      a {
        @apply text-zz-purple font-semibold;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      .link-container {
        flex:1;
        display: flex;
        align-items: flex-end;
      }
    }
  }
}